var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"calc(100vh - 20px)"},attrs:{"id":"login","tag":"section"}},[_c('b-row',{staticClass:"justify-content-center no-gutters"},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"lg":"12"}},[_c('b-card',{staticClass:"w-100",attrs:{"no-body":""}},[_c('b-row',{staticClass:"no-gutters",staticStyle:{"min-height":"100vh"}},[_c('b-col',{staticClass:"bg-primary d-md-flex justify-content-center",attrs:{"lg":"8"}},[_c('b-carousel',{staticClass:"bg-image d-flex justify-content-center align-items-center",staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"carousel-1","interval":5000,"controls":"","indicators":"","no-hover-pause":""},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},[_c('b-carousel-slide',{attrs:{"img-src":require("../../assets/images/carousel-1.png")}}),_c('b-carousel-slide',{attrs:{"img-src":require("../../assets/images/carousel-2.png")}})],1)],1),_c('b-col',{staticClass:"d-md-flex align-items-center justify-content-center",attrs:{"lg":"4"}},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('div',{staticClass:"p-sm-5 p-lg-0 p-xl-0 p-md-0 w-100"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"12","lg":"9","xl":"9"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticStyle:{"min-width":"300px","max-width":"50%"},attrs:{"src":require("@/assets/images/logo-softbusiness.png")}})]),_c('h2',{staticClass:"font-weight-bold mt-4 mb-4"},[_vm._v(" Iniciar sesión ")]),_c('b-form',[_c('b-form-group',[_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"txt-username","type":"text","placeholder":"Usuario","state":_vm.validateState(
                                                            'username'
                                                        )},on:{"input":(v) => {
                                                            _vm.$v.form.username.$model =
                                                                v.replace(
                                                                    /\s/g,
                                                                    ''
                                                                )
                                                        },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onLogin()}},model:{value:(
                                                        _vm.$v.form.username
                                                            .$model
                                                    ),callback:function ($$v) {_vm.$set(_vm.$v.form.username
                                                            , "$model", $$v)},expression:"\n                                                        $v.form.username\n                                                            .$model\n                                                    "}}),(
                                                        !_vm.$v.form.username
                                                            .minLength
                                                    )?_c('b-form-invalid-feedback',[_vm._v(" El nombre de usuario debe tener al menos "+_vm._s(_vm.$v.form.username .$params .minLength.min)+" caracteres ")]):_vm._e(),(
                                                        !_vm.$v.form.username
                                                            .required
                                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('b-form-group',[_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"txt-pwd","type":_vm.showPwd
                                                            ? 'text'
                                                            : 'password',"placeholder":"Contraseña","state":_vm.validateState('pwd')},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onLogin()}},model:{value:(
                                                        _vm.$v.form.pwd.$model
                                                    ),callback:function ($$v) {_vm.$set(_vm.$v.form.pwd, "$model", $$v)},expression:"\n                                                        $v.form.pwd.$model\n                                                    "}}),(
                                                        !_vm.$v.form.pwd
                                                            .minLength
                                                    )?_c('b-form-invalid-feedback',[_vm._v(" La contraseña debe tener al menos "+_vm._s(_vm.$v.form.pwd.$params .minLength.min)+" caracteres ")]):_vm._e(),(
                                                        !_vm.$v.form.pwd
                                                            .required
                                                    )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":true,"unchecked-value":false},model:{value:(_vm.showPwd),callback:function ($$v) {_vm.showPwd=$$v},expression:"showPwd"}},[_vm._v(" Mostrar contraseña ")])],1),_c('b-button',{staticClass:"mt-4",attrs:{"variant":"primary","block":"","disabled":_vm.isLoading,"size":"lg"},on:{"click":function($event){return _vm.onLogin()}}},[(_vm.isLoading)?_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Cargando...")])],1):_c('span',[_vm._v("Iniciar sesión")])]),_c('div',{staticClass:"d-flex align-items-center mt-4"},[_c('div',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(_vm.hovered),expression:"hovered"}],class:`ml-auto ${
                                                        _vm.isHovered
                                                            ? 'text-primary'
                                                            : ''
                                                    }`,attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.push(
                                                            '/auth/olvido-su-contrasena'
                                                        )}}},[_vm._v(" Olvidó su contraseña? ")])])],1)],1)],1)],1)])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }