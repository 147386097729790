<template>
    <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
        <b-row class="justify-content-center no-gutters">
            <b-col lg="12" class="d-flex align-items-center">
                <b-card no-body class="w-100">
                    <b-row class="no-gutters" style="min-height: 100vh">
                        <b-col
                            lg="8"
                            class="bg-primary d-md-flex justify-content-center"
                        >
                            <b-carousel
                                id="carousel-1"
                                v-model="slide"
                                :interval="5000"
                                controls
                                indicators
                                no-hover-pause
                                style="width: 100%; height: 100%"
                                class="bg-image d-flex justify-content-center align-items-center"
                                @sliding-start="onSlideStart"
                                @sliding-end="onSlideEnd"
                            >
                                <b-carousel-slide
                                    img-src="../../assets/images/carousel-1.png"
                                />

                                <b-carousel-slide
                                    img-src="../../assets/images/carousel-2.png"
                                />
                            </b-carousel>
                        </b-col>
                        <b-col
                            lg="4"
                            class="d-md-flex align-items-center justify-content-center"
                        >
                            <div class="d-flex align-items-center w-100">
                                <div class="p-sm-5 p-lg-0 p-xl-0 p-md-0 w-100">
                                    <b-row class="justify-content-center">
                                        <b-col cols="12" lg="9" xl="9">
                                            <div class="text-center">
                                                <img
                                                    src="@/assets/images/logo-softbusiness.png"
                                                    style="
                                                        min-width: 300px;
                                                        max-width: 50%;
                                                    "
                                                />
                                            </div>
                                            <h2
                                                class="font-weight-bold mt-4 mb-4"
                                            >
                                                Iniciar sesión
                                            </h2>

                                            <b-form>
                                                <b-form-group>
                                                    <b-form-input
                                                        id="txt-username"
                                                        type="text"
                                                        placeholder="Usuario"
                                                        class="mb-3"
                                                        v-model="
                                                            $v.form.username
                                                                .$model
                                                        "
                                                        @input="
                                                            (v) => {
                                                                $v.form.username.$model =
                                                                    v.replace(
                                                                        /\s/g,
                                                                        ''
                                                                    )
                                                            }
                                                        "
                                                        :state="
                                                            validateState(
                                                                'username'
                                                            )
                                                        "
                                                        @keydown.enter="
                                                            onLogin()
                                                        "
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback
                                                        v-if="
                                                            !$v.form.username
                                                                .minLength
                                                        "
                                                    >
                                                        El nombre de usuario
                                                        debe tener al menos
                                                        {{
                                                            $v.form.username
                                                                .$params
                                                                .minLength.min
                                                        }}
                                                        caracteres
                                                    </b-form-invalid-feedback>
                                                    <b-form-invalid-feedback
                                                        v-if="
                                                            !$v.form.username
                                                                .required
                                                        "
                                                    >
                                                        Campo requerido
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                                <b-form-group>
                                                    <b-form-input
                                                        id="txt-pwd"
                                                        :type="
                                                            showPwd
                                                                ? 'text'
                                                                : 'password'
                                                        "
                                                        placeholder="Contraseña"
                                                        class="mb-3"
                                                        v-model="
                                                            $v.form.pwd.$model
                                                        "
                                                        :state="
                                                            validateState('pwd')
                                                        "
                                                        @keydown.enter="
                                                            onLogin()
                                                        "
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback
                                                        v-if="
                                                            !$v.form.pwd
                                                                .minLength
                                                        "
                                                    >
                                                        La contraseña debe tener
                                                        al menos
                                                        {{
                                                            $v.form.pwd.$params
                                                                .minLength.min
                                                        }}
                                                        caracteres
                                                    </b-form-invalid-feedback>
                                                    <b-form-invalid-feedback
                                                        v-if="
                                                            !$v.form.pwd
                                                                .required
                                                        "
                                                    >
                                                        Campo requerido
                                                    </b-form-invalid-feedback>
                                                </b-form-group>

                                                <div
                                                    class="d-flex align-items-center mb-4"
                                                >
                                                    <b-form-checkbox
                                                        id="checkbox-1"
                                                        v-model="showPwd"
                                                        name="checkbox-1"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                    >
                                                        Mostrar contraseña
                                                    </b-form-checkbox>
                                                </div>

                                                <b-button
                                                    variant="primary"
                                                    block
                                                    :disabled="isLoading"
                                                    size="lg"
                                                    class="mt-4"
                                                    @click="onLogin()"
                                                >
                                                    <span
                                                        v-if="isLoading"
                                                        class="d-flex align-items-center justify-content-center"
                                                    >
                                                        <b-spinner
                                                            small
                                                        ></b-spinner>
                                                        <span class="ml-2"
                                                            >Cargando...</span
                                                        >
                                                    </span>
                                                    <span v-else
                                                        >Iniciar sesión</span
                                                    >
                                                </b-button>

                                                <div
                                                    class="d-flex align-items-center mt-4"
                                                >
                                                    <div
                                                        :class="`ml-auto ${
                                                            isHovered
                                                                ? 'text-primary'
                                                                : ''
                                                        }`"
                                                        v-b-hover="hovered"
                                                        role="button"
                                                        @click="
                                                            $router.push(
                                                                '/auth/olvido-su-contrasena'
                                                            )
                                                        "
                                                    >
                                                        Olvidó su contraseña?
                                                    </div>
                                                </div>
                                            </b-form>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'FullLogin',
    mixins: [validationMixin],
    data: () => ({
        slide: 0,
        sliding: null,
        isLoading: false,
        isHovered: false,
        form: {
            username: '',
            pwd: ''
        },
        showPwd: false
    }),
    computed: {},
    validations: {
        form: {
            username: {
                required,
                minLength: minLength(4)
            },
            pwd: {
                required,
                minLength: minLength(8)
            }
        }
    },
    methods: {
        validateState(username) {
            const { $dirty, $error } = this.$v.form[username]
            return $dirty ? !$error : null
        },
        hovered(value) {
            this.isHovered = value
        },
        onLogin(forced = false) {
            if (!this.$v.form.$invalid) {
                this.isLoading = true
                const { username, pwd: password } = this.form

                API.POST({
                    url: 'auth/login',
                    data: {
                        username,
                        password,
                        forced
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception },
                                dato
                            }
                        } = response
                        if (status === 'ok') {
                            this.login(dato)
                        } else if (status === 'warn') {
                            this.isLoading = false
                            this.$bvModal
                                .msgBoxConfirm(mensaje, {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'danger',
                                    okTitle: 'Continuar',
                                    cancelTitle: 'Cancelar',
                                    footerClass:
                                        'p-2 d-flex justify-content-between',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'danger',
                                    headerClass: 'modal-header'
                                })
                                .then((value) => {
                                    if (value) {
                                        this.onLogin(true)
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            this.isLoading = false
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.isLoading = false
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.form.$touch()
            }
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        ...mapActions('usuario', ['login'])
    }
}
</script>

<style></style>
